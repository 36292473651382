/* You can add global styles to this file, and also import other style files */
@import '~@cds/core/styles/theme.dark.min.css';

@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lobster/v23/neILzCirqoswsqX9zoamM4MwWJXNqA.woff2) format('woff2');
}

body {
  background-color: #333;
  color: lightgray;
}

.hf-title {
  color: white !important;
}

.hf-content-container {
  border: 2px solid darkslategray;
  background: #333;
  border-radius: 15px;
  min-height: 200px;
  margin-top: 2%;
}

.hf-first-content-container {
  border: 2px solid darkslategray;
  background: #333;
  border-radius: 15px;
  min-height: 200px;
  margin-top: 3%;
  padding-bottom: 2%;
}
.clr-vertical-nav .nav-trigger cds-icon[shape=angle-double], .clr-vertical-nav .nav-group-trigger cds-icon[shape=angle-double] {
  color: white !important;
}
.hf-logo-container {
  background: #060606 url("/assets/img/spiral.gif") 15% 25% fixed;
  background-size:cover;
  padding: 0;
}
.hf-header-blur {
  background: rgba(50,50,50,.94);
  padding: 0 15px 0 15px;
  border-radius: 13px;
}
